import { createRoot } from 'react-dom/client';

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

function HomePage () {
  return (
    <div>
      Hi
    </div>
  )
}


root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
);